"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAreasWithLanguageCodeModelSchema = exports.setAreasModelSchema = exports.performEmailConfirmationSchema = exports.createEmailConfirmationSchema = exports.accountReferenceWithDateSchema = exports.accountReferenceSchema = exports.accountIdSchema = void 0;
const zod_1 = require("zod");
exports.accountIdSchema = zod_1.z.object({
    id: zod_1.z.string(),
});
exports.accountReferenceSchema = zod_1.z.object({
    id: zod_1.z.string(),
    email: zod_1.z.string().email().optional(),
    name: zod_1.z.string().optional(),
});
exports.accountReferenceWithDateSchema = exports.accountReferenceSchema.extend({
    date: zod_1.z.date(),
});
exports.createEmailConfirmationSchema = zod_1.z.object({
    userId: (0, zod_1.string)(),
    email: (0, zod_1.string)().email(),
});
exports.performEmailConfirmationSchema = zod_1.z.object({
    email: zod_1.z.string().email(),
    token: zod_1.z.string(),
});
exports.setAreasModelSchema = zod_1.z.object({
    accountId: zod_1.z.string(),
    areas: zod_1.z.string().array(),
});
exports.setAreasWithLanguageCodeModelSchema = exports.setAreasModelSchema.extend({
    langCode: zod_1.z.string(),
});
