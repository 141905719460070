import styles from './footer.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Container fluid>
        <Row>
          <Col className={styles.content}>
            {/*<div className={'footer-image-container'}>*/}
            {/*  <Image src='/logos/timetorock_logo.png' />*/}
            {/*</div>*/}
            <p>TIME TO ROCK FESTIVAL</p>
            <p>Knislinge Folkets Park</p>
            <p>Parkgatan 7, 289 33 Knislinge</p>

            <div id="cob_3" className="cob cot_5" data-width="1000">
              <span className={styles.socialMediaHolder}>
                <a
                  className={styles.socialMediaLink}
                  href="mailto:info@timetorockfestival.se"
                  target="_blank"
                  rel="noreferrer"
                  title="Gå till vår E-post-sida"
                >
                  <svg
                    className={styles.socialMediaIcon}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fal"
                    data-icon="envelope"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"
                    ></path>
                  </svg>
                  {/*<i class="fal fa-envelope"></i> Font Awesome fontawesome.com*/}
                </a>
                <a
                  className={styles.socialMediaLink}
                  href="https://www.facebook.com/timetorockfestival"
                  target="_blank"
                  rel="noreferrer"
                  title="Gå till vår Facebook-sida"
                >
                  <svg
                    className={styles.socialMediaIcon}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                    ></path>
                  </svg>
                  {/*<i class="fab fa-facebook"></i> Font Awesome fontawesome.com*/}
                </a>
                <a
                  className={styles.socialMediaLink}
                  href="https://www.instagram.com/timetorockfestival/"
                  target="_blank"
                  rel="noreferrer"
                  title="Gå till vår Instagram-sida"
                >
                  <svg
                    className={styles.socialMediaIcon}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    ></path>
                  </svg>
                  {/*<i class="fab fa-instagram"></i> Font Awesome fontawesome.com -->*/}
                </a>
                <a
                  className={styles.socialMediaLink}
                  href="https://open.spotify.com/playlist/0UyfoX7Mk9rp5Aa6qEXdom?si=32e25d1ae1db499a"
                  target="_blank"
                  rel="noreferrer"
                  title="Gå till vår Spotify-sida"
                >
                  <svg
                    className={styles.socialMediaIcon}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="spotify"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z"
                    ></path>
                  </svg>
                  {/*<i class="fab fa-spotify"></i> Font Awesome fontawesome.com*/}
                </a>
                <a
                  className={styles.socialMediaLink}
                  href="https://www.youtube.com/channel/UCY8-2Ihwz1vXBOED-d8DlRg?view_as=subscriber"
                  target="_blank"
                  rel="noreferrer"
                  title="Gå till vår YouTube-sida"
                >
                  <svg
                    className={styles.socialMediaIcon}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="youtube"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                    ></path>
                  </svg>
                  {/*<i class="fab fa-youtube"></i> Font Awesome fontawesome.com*/}
                </a>
              </span>
            </div>

            <p>Köpvillkor | Coockie policy | FAQ | Kontakt</p>
            <p>Prenumerera på vårt nyhetsbrev</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
