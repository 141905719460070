"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userProfileSchema = void 0;
const zod_1 = require("zod");
exports.userProfileSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    email: zod_1.z.string().email(),
    emailVerified: zod_1.z.date().optional(),
    address: zod_1.z.string().optional(),
    postalCode: zod_1.z.string().optional(),
    city: zod_1.z.string().optional(),
    country: zod_1.z.string().optional(),
    phoneNumber: zod_1.z
        .string()
        .regex(/^\+\d{6,15}$/g)
        .optional()
        .or(zod_1.z.literal('')),
    image: zod_1.z.string().optional(),
    accountLocked: zod_1.z.boolean(),
    userRole: zod_1.z.string().optional(),
});
