import React, { useEffect, useState } from 'react';
import * as contentServices from '../services/contentServices';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import handlebars from 'handlebars';

export const ContentDisplay = (props: {
  contentKey: string;
  languageCode: string;
}) => {
  const { data: session } = useSession();
  const { locale } = useRouter();
  const [pageContent, setPageContent] = useState<string>('');

  function evalPrecompiledTemplate(s: string) {
    return eval(`(function(){return ${s}}());`);
  }

  const getContent = async (): Promise<string> => {
    console.log({
      key: props.contentKey,
      language: props.languageCode,
    });
    const contentResult = await contentServices.getContentList(
      {
        key: props.contentKey,
        language: props.languageCode,
      },
      (e) => console.log(`Error getting content '${props.contentKey}'`, e)
    );
    if (!contentResult.success || !contentResult.data) {
      return '';
    }
    const content = contentResult.data;
    if (content.length <= 0) {
      return '';
    }
    const theContent = content[0];
    const isLoggedIn = !!session;
    const isCrew = session?.user?.crewApproved;
    const isBand = false;
    try {
      if (theContent.template) {
        const template = theContent.template
          ? handlebars.template(evalPrecompiledTemplate(theContent.template))
          : handlebars.compile(theContent.text);
        const context = {
          isLoggedIn: isLoggedIn,
          isAnonymous: !isLoggedIn,
          isCrew: isCrew,
          isBand: isBand,
          isOnlyVisitor: !isCrew && !isBand,
          user: session?.user,
        };
        return template(context);
      } else {
        return theContent.text;
      }
    } catch (e) {
      console.log(
        `Failure to apply the handlebars template "${props.contentKey}:${props.languageCode}"`,
        e
      );
      return '';
    }
  };

  useEffect(() => {
    (async () => {
      setPageContent(await getContent());
    })();
  }, [session, locale]);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: pageContent }} />
    </>
  );
};
