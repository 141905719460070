"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pressApplicationAccountGetSchema = exports.pressApplicationApprovalModelSchema = exports.pressApplicationGetSchema = exports.pressApplicationCreationSchema = exports.pressApplicationCreateSchema = exports.pressStatuses = exports.pressApplicationStatuses = void 0;
const zod_1 = require("zod");
const account_1 = require("../account");
const misc_1 = require("../misc");
const type_safeguards_1 = require("@ackreditering/type-safeguards");
exports.pressApplicationStatuses = [
    type_safeguards_1.ApplicationStatusEnum.currentlyProcessing,
    type_safeguards_1.ApplicationStatusEnum.paused,
    type_safeguards_1.ApplicationStatusEnum.rejected,
    type_safeguards_1.ApplicationStatusEnum.accepted,
    type_safeguards_1.ApplicationStatusEnum.ignored,
];
exports.pressStatuses = [
    type_safeguards_1.AccountApplicationStatusEnum.Approved,
    type_safeguards_1.AccountApplicationStatusEnum.Rejected,
    type_safeguards_1.AccountApplicationStatusEnum.No,
];
exports.pressApplicationCreateSchema = zod_1.z.object({
    writingFor: zod_1.z
        .string()
        .min(3, { message: 'press.application.form.writingFor.mandatory' }),
    gdprConsent: zod_1.z.literal(true, {
        errorMap: () => ({ message: 'press.application.form.error.gdprConsent' }),
    }),
    additionalInfo: zod_1.z.string().optional().or(zod_1.z.literal('')),
});
exports.pressApplicationCreationSchema = exports.pressApplicationCreateSchema.extend({
    accountId: zod_1.z.string(),
});
exports.pressApplicationGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    writingFor: zod_1.z.string().min(3),
    gdprConsent: zod_1.z.boolean(),
    additionalInfo: zod_1.z.string().optional().or(zod_1.z.literal('')),
    applicationStatus: zod_1.z.enum(exports.pressApplicationStatuses),
    applicationDate: zod_1.z.date(),
});
exports.pressApplicationApprovalModelSchema = exports.pressApplicationGetSchema.extend({
    pressApplicationAccountId: zod_1.z.string(),
});
exports.pressApplicationAccountGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    account: account_1.accountReferenceSchema,
    applications: exports.pressApplicationGetSchema.array(),
    comments: misc_1.commentSchema.array(),
    createdAt: zod_1.z.date(),
    lastUpdateAt: zod_1.z.date().optional(),
});
