"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.identifierAreaScheduleSchema = exports.updateAreaScheduleSchema = exports.getAreaScheduleSchema = exports.createAreaScheduleSchema = exports.areaScheduleAssignmentSchema = void 0;
const zod_1 = require("zod");
const accountMisc_1 = require("../account/accountMisc");
exports.areaScheduleAssignmentSchema = zod_1.z.object({
    assignedTo: accountMisc_1.accountReferenceSchema,
    startTimeInUTCSeconds: zod_1.z.number().int(),
    endTimeInUTCSeconds: zod_1.z.number().int(),
    equipmentToCheckOutIds: zod_1.z.string().array(),
});
const _createAreaScheduleSchema = zod_1.z.object({
    date: zod_1.z.date(),
    areaId: zod_1.z.string().min(1),
    managers: accountMisc_1.accountReferenceSchema.array(),
    assignments: exports.areaScheduleAssignmentSchema.array().optional(),
});
exports.createAreaScheduleSchema = _createAreaScheduleSchema
    .refine((schedule) => schedule.assignments &&
    schedule.assignments.length > 0 &&
    schedule.managers.length === 0, 'schedule.at least one manager needs to be specified if assignments exist')
    .refine((schedule) => schedule.date.getTime() !== 0, 'schedule.date is not permitted to contain a time component');
exports.getAreaScheduleSchema = _createAreaScheduleSchema.extend({
    id: zod_1.z.string(),
    createdAt: zod_1.z.date(),
    assignments: exports.areaScheduleAssignmentSchema.array(),
    lastUpdateAt: zod_1.z.date().optional(),
});
const _updateAreaScheduleSchema = zod_1.z.object({
    managers: accountMisc_1.accountReferenceSchema.array().min(1),
    assignments: exports.areaScheduleAssignmentSchema.array(),
});
exports.updateAreaScheduleSchema = _updateAreaScheduleSchema.refine((schedule) => schedule.assignments &&
    schedule.assignments.length > 0 &&
    schedule.managers.length === 0, 'schedule.at least one manager needs to be specified if assignments exist');
exports.identifierAreaScheduleSchema = zod_1.z.object({
    date: zod_1.z.date(),
    areaId: zod_1.z.string(),
});
