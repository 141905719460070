import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSession } from 'next-auth/react';
import { navLinks, IMenuItem } from '../lib/routes';
import NavBarProfile from '../components/navBarProfile';
import NavLocalization from '../components/navLocalization';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';

export default function NavTopMenu() {
  const { data: session } = useSession();
  const intl = useIntl();

  const filterOnRequiredRoles = (
    requiredRoles: string | string[] | undefined
  ) => {
    if (!requiredRoles) return true;
    const requiredRolesArray =
      typeof requiredRoles === 'string' ? [requiredRoles] : requiredRoles;
    return (
      session?.user?.userRole &&
      requiredRolesArray.includes(session.user.userRole)
    );
  };

  const renderMenus = (
    menuItems: IMenuItem[],
    isInDropdown: boolean = false
  ) => {
    return menuItems
      .filter((menuItem) => filterOnRequiredRoles(menuItem.requiredRoles))
      .map((menuItem, index) => {
        if (menuItem.childItems)
          return (
            <NavDropdown
              key={index}
              title={intl.formatMessage({ id: `menu:${menuItem.name}` })}
              menuVariant="dark"
            >
              {renderMenus(menuItem.childItems, true)}
            </NavDropdown>
          );

        if (menuItem.name === '---') return <NavDropdown.Divider key={index} />;
        return isInDropdown ? (
          <NavDropdown.Item key={index} href={menuItem.path}>
            <FormattedMessage id={`menu:${menuItem.name}`} />
          </NavDropdown.Item>
        ) : (
          <Nav.Link key={index} href={menuItem.path}>
            <FormattedMessage id={`menu:${menuItem.name}`} />
          </Nav.Link>
        );
      });
  };

  return (
    <Navbar bg="dark" variant="dark" expand="sm" sticky={'top'}>
      <Container fluid>
        {/*<Navbar.Brand className={'time-to-rock-logo-menu'} href="/" title={'Time To Rock'}></Navbar.Brand>*/}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <>{renderMenus(navLinks)}</>
          </Nav>
          <NavLocalization />
          <NavBarProfile />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
