export enum Language {
  DANISH = 'da',
  SWEDISH = 'sv',
  NORWEGIAN = 'no',
  ENGLISH = 'en',
  GERMAN = 'de',
}

export const isValidLanguage = (language: string): language is Language =>
  Object.values<string>(Language).includes(language);
