import { AccountRoleEnum } from '@ackreditering/type-safeguards/src';

export interface IMenuItem {
  name: string;
  path: string;
  requiredRoles?: string | string[] | undefined;
  childItems?: IMenuItem[];
}
export const navLinks: IMenuItem[] = [
  {
    name: 'home',
    path: '/',
  },
  {
    name: 'about.us',
    path: '/about',
  },
  {
    name: 'admin',
    path: '/',
    requiredRoles: [
      AccountRoleEnum.superAdmin,
      AccountRoleEnum.admin,
      AccountRoleEnum.areaManager,
    ],
    childItems: [
      {
        name: 'application.crew',
        path: '/admin/crew/list-applications',
      },
      {
        name: 'application.press',
        path: '/admin/press/list-applications',
      },
      {
        name: 'accounts.and.permissions',
        path: '/admin/accountList',
        requiredRoles: [AccountRoleEnum.superAdmin, AccountRoleEnum.admin],
      },
      {
        name: 'accounts.and.equipment',
        path: '/admin/accounts/assignEquipment',
      },
      {
        name: 'release.equipment',
        path: '/admin/accounts/releaseEquipment',
      },
      {
        name: 'crew.my.people',
        path: '/admin/crew/my-people',
      },
      {
        name: 'press.accredited',
        path: '/admin/press/accredited',
      },
      {
        name: 'guest.lists',
        path: '/admin/tickets/guestLists',
      },
      {
        name: 'content.area.equipment',
        path: '/admin/content/contentAdmin',
        requiredRoles: [AccountRoleEnum.superAdmin],
      },
      {
        name: 'reports.and.information',
        path: '/admin/reportsAndInformation',
        requiredRoles: [AccountRoleEnum.admin, AccountRoleEnum.superAdmin],
      },
    ],
  },
  {
    name: 'work.as.crew',
    path: '/',
    childItems: [
      {
        name: 'crew.information',
        path: '/crew/info',
      },
      {
        name: '---',
        path: '/',
      },
      {
        name: 'apply.for.crew',
        path: '/apply-crew',
      },
    ],
  },
  {
    name: 'press',
    path: '/',
    childItems: [
      {
        name: 'apply.for.press',
        path: '/apply-press',
      },
    ],
  },
  {
    name: 'contact.us',
    path: '#contact',
  },
];
