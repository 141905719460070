import axios from 'axios';
import { AxiosError } from 'axios';
import {
  ResultType,
  ResultTypeWithId,
  ResultTypeWithData,
} from '../types/resultTypes';
import {
  ContentCreate,
  ContentUpdate,
  ContentGet,
  ContentGetInfo,
  ContentFilter,
  contentUpdateSchema,
  contentCreateSchema,
} from '@ackreditering/viewModels';
import { YURL } from 'yurl';

const apiBaseUrl = new YURL(
  process.env.NEXT_PUBLIC_API_BASE_URL || ''
).pathname('api/content/');

export const getContentList = async (
  filter: ContentFilter,
  trackException: (e: Error) => void
): Promise<ResultTypeWithData<ContentGetInfo[]>> => {
  try {
    const options = {
      timeout: 10000,
      data: '',
      url: apiBaseUrl
        .clone()
        .query(filter as Record<string, string>)
        .format(),
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(options);
    const { status, data } = response;
    if (data != null && status >= 200 && status < 300 && data.success) {
      return {
        success: true,
        data: data.data,
      };
    } else {
      return {
        success: false,
        statusCode: status,
        errorMessageCode: data.errorMessageCode,
        collision: data.collision,
      };
    }
  } catch (e) {
    trackException(new Error(`Failed to get content. ${e}`));
    if (e instanceof AxiosError) {
      const axiosError = e as AxiosError;
      console.log(
        'Axios error. Code:',
        axiosError.code,
        ' Status:',
        axiosError.status
      );
      console.log(
        'Axios error. Response Status:',
        axiosError.response?.status,
        ' Data:',
        axiosError.response?.data
      );
      return {
        success: false,
        statusCode: axiosError.response?.status,
        errorMessageCode:
          (axiosError.response?.data as any)?.errorMessageCode ??
          'error.fetch.failed',
        collision: (axiosError.response?.data as any)?.collision ?? false,
      };
    }
    return {
      success: false,
      errorMessageCode: 'error.fetch.failed',
    };
  }
};

export const getContent = async (
  id: string,
  trackException: (e: Error) => void
): Promise<ResultType | ResultTypeWithData<ContentGet>> => {
  try {
    const options = {
      timeout: 10000,
      data: '',
      url: apiBaseUrl.clone().format(),
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(options);
    const { status, data } = response;
    if (data != null && status >= 200 && status < 300 && data.success) {
      return {
        success: true,
        data: data.data,
      };
    } else {
      return {
        success: false,
        statusCode: status,
        errorMessageCode: data.errorMessageCode,
        collision: data.collision,
      };
    }
  } catch (e) {
    trackException(new Error(`Failed to get content. ${e}`));
    if (e instanceof AxiosError) {
      const axiosError = e as AxiosError;
      console.log(
        'Axios error. Code:',
        axiosError.code,
        ' Status:',
        axiosError.status
      );
      console.log(
        'Axios error. Response Status:',
        axiosError.response?.status,
        ' Data:',
        axiosError.response?.data
      );
      return {
        success: false,
        statusCode: axiosError.response?.status,
        errorMessageCode:
          (axiosError.response?.data as any)?.errorMessageCode ??
          'error.fetch.failed',
        collision: (axiosError.response?.data as any)?.collision ?? false,
      };
    }
    return {
      success: false,
      errorMessageCode: 'error.fetch.failed',
    };
  }
};

export const deleteContent = async (
  id: string,
  trackException: (e: Error) => void
): Promise<ResultType> => {
  try {
    const options = {
      timeout: 10000,
      data: '',
      url: apiBaseUrl.clone().pathname(`${id}`).format(),
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const response = await axios(options);
    const { status, data } = response;
    if (data != null && status >= 200 && status < 300) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        statusCode: status,
        errorMessageCode: data.errorMessageCode ?? 'error.deletion.failed',
        notFound: data.notFound,
      };
    }
  } catch (e) {
    trackException(new Error(`Failed to delete content. ${e}`));
    if (e instanceof AxiosError) {
      const axiosError = e as AxiosError;
      console.log(
        'Axios error. Code:',
        axiosError.code,
        ' Status:',
        axiosError.status
      );
      console.log(
        'Axios error. Response Status:',
        axiosError.response?.status,
        ' Data:',
        axiosError.response?.data
      );
      return {
        success: false,
        statusCode: axiosError.response?.status,
        errorMessageCode:
          (axiosError.response?.data as any)?.errorMessageCode ??
          'error.deletion.failed',
      };
    }
    return {
      success: false,
      errorMessageCode: 'error.deletion.failed',
    };
  }
};

export const createContent = async (
  content: ContentCreate,
  trackException: (e: Error) => void
): Promise<ResultType | ResultTypeWithId> => {
  if (!(await contentCreateSchema.safeParseAsync(content)).success) {
    trackException(new Error('Data format error.'));
    return {
      success: false,
      errorMessageCode: 'error.data.format',
    };
  }
  try {
    const options = {
      timeout: 10000,
      data: content,
      url: apiBaseUrl.format(),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    console.log('Sending:', content);
    const response = await axios(options);
    const { status, data } = response;
    if (data != null && status >= 200 && status < 300) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        statusCode: status,
        errorMessageCode: data.errorMessageCode,
        notFound: data.notFound,
        collision: data.collision,
      };
    }
  } catch (e) {
    trackException(new Error(`Failed to create content. ${e}`));
    if (e instanceof AxiosError) {
      const axiosError = e as AxiosError;
      console.log(
        'Axios error. Code:',
        axiosError.code,
        ' Status:',
        axiosError.status
      );
      console.log(
        'Axios error. Response Status:',
        axiosError.response?.status,
        ' Data:',
        axiosError.response?.data
      );
      return {
        success: false,
        statusCode: axiosError.response?.status,
        errorMessageCode:
          (axiosError.response?.data as any)?.errorMessageCode ??
          'error.create.failed',
        collision: (axiosError.response?.data as any)?.collision ?? false,
      };
    }
    return {
      success: false,
      errorMessageCode: 'error.create.failed',
    };
  }
};

export const updateContent = async (
  content: ContentUpdate,
  trackException: (e: Error) => void
): Promise<ResultType> => {
  const contentValidation = await contentUpdateSchema.safeParseAsync(content);
  if (!contentValidation.success) {
    console.log('Format error:', contentValidation.error);
    trackException(new Error('Data format error.'));
    return {
      success: false,
      errorMessageCode: 'error.data.format',
    };
  }
  const { id, ...parameters } = content;
  try {
    const options = {
      timeout: 10000,
      data: parameters,
      url: apiBaseUrl.clone().pathname(`${id}`).format(),
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    console.log('Sending:', parameters);
    const response = await axios(options);
    const { status, data } = response;
    if (data != null && status >= 200 && status < 300) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        statusCode: status,
        errorMessageCode: data.errorMessageCode,
        notFound: data.notFound,
        collision: data.collision,
      };
    }
  } catch (e) {
    trackException(new Error(`Failed to update content. ${e}`));
    if (e instanceof AxiosError) {
      const axiosError = e as AxiosError;
      console.log(
        'Axios error. Code:',
        axiosError.code,
        ' Status:',
        axiosError.status
      );
      console.log(
        'Axios error. Response Status:',
        axiosError.response?.status,
        ' Data:',
        axiosError.response?.data
      );
      return {
        success: false,
        statusCode: axiosError.response?.status,
        errorMessageCode:
          (axiosError.response?.data as any)?.errorMessageCode ??
          'error.update.failed',
        collision: (axiosError.response?.data as any)?.collision ?? false,
      };
    }
    return {
      success: false,
      errorMessageCode: 'error.update.failed',
    };
  }
};
