"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crewApplicationAccountGetSchema = exports.crewApplicationApprovalModelSchema = exports.crewApplicationGetSchema = exports.crewApplicationCreationSchema = exports.crewApplicationCreateSchema = exports.crewStatuses = exports.crewApplicationStatuses = exports.accountActionsAfterFestival = exports.shirtSizes = void 0;
const zod_1 = require("zod");
const account_1 = require("../account");
const misc_1 = require("../misc");
const src_1 = require("@ackreditering/type-safeguards/src");
exports.shirtSizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'];
exports.accountActionsAfterFestival = [
    src_1.AfterFestivalEnum.remainAvailableAsCrew,
    src_1.AfterFestivalEnum.removeFromCrew,
    src_1.AfterFestivalEnum.removeAccount,
];
exports.crewApplicationStatuses = [
    src_1.ApplicationStatusEnum.currentlyProcessing,
    src_1.ApplicationStatusEnum.paused,
    src_1.ApplicationStatusEnum.rejected,
    src_1.ApplicationStatusEnum.accepted,
    src_1.ApplicationStatusEnum.ignored,
];
exports.crewStatuses = [
    src_1.AccountApplicationStatusEnum.Approved,
    src_1.AccountApplicationStatusEnum.Rejected,
    src_1.AccountApplicationStatusEnum.No,
];
exports.crewApplicationCreateSchema = zod_1.z.object({
    areas: zod_1.z
        .string()
        .array()
        // .min(1, 'crew.application.form.error.areas.at.least.one')
        .optional(),
    priorExperience: zod_1.z.string().optional(),
    canWorkNumberHours: zod_1.z
        .number()
        .gte(1, 'crew.application.form.error.canWorkNumberHours.at.least.one'),
    canWorkDates: zod_1.z.date().array().min(1, 'At least one day'),
    canWorkConsecutiveShifts: zod_1.z.boolean(),
    shirtSize: zod_1.z.enum(exports.shirtSizes, {
        errorMap: () => ({ message: 'crew.application.form.error.shirtSize' }),
    }),
    gdprConsent: zod_1.z.literal(true, {
        errorMap: () => ({ message: 'crew.application.form.error.gdprConsent' }),
    }),
    workTermsApproval: zod_1.z.literal(true, {
        errorMap: () => ({
            message: 'crew.application.form.error.workTermsApproval',
        }),
    }),
    afterFestival: zod_1.z.enum(exports.accountActionsAfterFestival, {
        errorMap: () => ({ message: 'Välj vad som händer efter festival' }),
    }),
    okToSendImportantCrewInformation: zod_1.z.object({
        asEmail: zod_1.z.boolean(),
        asTextMessages: zod_1.z.boolean(),
    }),
    okToSendImportantCrewAlerts: zod_1.z.object({
        asTextMessages: zod_1.z.literal(true, {
            errorMap: () => ({
                message: 'crew.application.form.error.okToSendImportantCrewAlerts.asTextMessages',
            }),
        }),
    }),
    foodPreference: zod_1.z.string().optional(),
    allergies: zod_1.z.string().optional(),
    additionalInfo: zod_1.z.string().optional(),
});
exports.crewApplicationCreationSchema = exports.crewApplicationCreateSchema.extend({
    accountId: zod_1.z.string(),
});
exports.crewApplicationGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    areas: zod_1.z.string().array().min(1),
    priorExperience: zod_1.z.string().optional(),
    canWorkNumberHours: zod_1.z.number().gte(1),
    canWorkDates: zod_1.z.date().array().min(1),
    canWorkConsecutiveShifts: zod_1.z.boolean(),
    shirtSize: zod_1.z.enum(exports.shirtSizes),
    gdprConsent: zod_1.z.boolean(),
    workTermsApproval: zod_1.z.boolean(),
    afterFestival: zod_1.z.enum(exports.accountActionsAfterFestival),
    okToSendImportantCrewInformation: zod_1.z.object({
        asEmail: zod_1.z.boolean(),
        asTextMessages: zod_1.z.boolean(),
    }),
    okToSendImportantCrewAlerts: zod_1.z.object({
        asTextMessages: zod_1.z.boolean(),
    }),
    foodPreference: zod_1.z.string().optional(),
    allergies: zod_1.z.string().optional().or(zod_1.z.literal('')),
    additionalInfo: zod_1.z.string().optional().or(zod_1.z.literal('')),
    applicationStatus: zod_1.z.enum(exports.crewApplicationStatuses),
    applicationDate: zod_1.z.date(),
});
exports.crewApplicationApprovalModelSchema = exports.crewApplicationGetSchema.extend({
    crewApplicationAccountId: zod_1.z.string(),
});
exports.crewApplicationAccountGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    account: account_1.accountReferenceSchema,
    applications: exports.crewApplicationGetSchema.array(),
    comments: misc_1.commentSchema.array(),
    createdAt: zod_1.z.date(),
    lastUpdateAt: zod_1.z.date().optional(),
});
