"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lockUnlockWithPerformerSchema = exports.lockUnlockSchema = void 0;
const zod_1 = require("zod");
exports.lockUnlockSchema = zod_1.z.object({
    accountId: zod_1.z.string(),
});
exports.lockUnlockWithPerformerSchema = exports.lockUnlockSchema.extend({
    lockStatusChangedById: zod_1.z.string(),
});
