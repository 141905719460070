import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import LogoImage from '../../public/Time-to-Rock-Festival-custom.webp';
import { useSession } from 'next-auth/react';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ContentDisplay } from '../components/contentDisplay';

export default function IndexPage() {
  const { data: session } = useSession();
  const { locale } = useRouter();

  const langCode = locale?.substring(0, 2) ?? 'en';
  const contentKey = 'start page';

  useEffect(() => {
    (async () => {
      // window.signIn = signIn();
    })();
  }, [session, langCode]);

  return (
    <Layout>
      <Image
        src={LogoImage.src}
        alt="Time To Rock festival 5 - 8 Juli 2024"
        style={{ width: '100%' }}
      />

      <Row>
        <Col md={{ offset: 2, span: 8 }} sm={12}>
          <ContentDisplay contentKey={contentKey} languageCode={langCode} />
        </Col>
      </Row>
    </Layout>
  );
}
