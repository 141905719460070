export enum ApplicationStatusEnum {
  currentlyProcessing = 'processing',
  paused = 'paused',
  rejected = 'rejected',
  accepted = 'accepted',
  ignored = 'ignored',
}

export enum AfterFestivalEnum {
  removeAccount = 'Remove account',
  removeFromCrew = 'Remove from crew',
  remainAvailableAsCrew = 'Remain available as crew',
}
