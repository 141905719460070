"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crewApplicationCreateUISchema = void 0;
const zod_1 = require("zod");
const crewApplication_1 = require("./crewApplication");
// const passwordRegex = /^(?=.*\d)(?=.*[A-Za-z]).{10,}$/gm;
// /^(?=.*\d)(?=.*[!"#¤%&/()=?@£${[\]}€*'+<>\\\-;:,._ ])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
exports.crewApplicationCreateUISchema = crewApplication_1.crewApplicationCreateSchema
    .extend({
    canWorkDates: zod_1.z
        .string()
        .datetime({
        offset: true,
        message: 'crew.application.form.error.canWorkDates.invalid.date',
    })
        .array()
        .min(1, 'crew.application.form.error.canWorkDates.at.least.one'),
    // These are for the use case that the account isn't created yet. This will enable doing the two steps of
    // creating the account and registering for crew work in one go visually in the UI.
    firstName: zod_1.z
        .string()
        .min(1, 'user.registration.form.firstName.must.be.long'),
    lastName: zod_1.z.string().min(1, 'user.registration.form.lastName.must.be.long'),
    email: zod_1.z
        .string()
        .min(1, 'user.registration.form.email.mandatory')
        .email('user.registration.form.email.invalid'),
    existingAccount: zod_1.z.boolean().optional(),
    oldPassword: zod_1.z.string().nullish(),
    password: zod_1.z.string(),
    passwordValidation: zod_1.z.string(),
    phoneNumber: zod_1.z
        .string()
        .min(6, 'user.registration.form.phoneNumber.mandatory')
        .regex(/^\+\d{8,}$/gm, 'user.registration.form.phoneNumber.invalid'),
    address: zod_1.z.string().optional(),
    postalCode: zod_1.z.string().nullish(),
    city: zod_1.z.string().nullish(),
    country: zod_1.z.string().nullish(),
    personalIdentificationNumber: zod_1.z
        .string()
        .min(1, 'user.registration.form.personalIdentificationNumber.mandatory'),
})
    .superRefine((val, ctx) => {
    var _a, _b;
    const existingAccount = val.existingAccount;
    const nonEmptyPassword = !!val.password;
    // const invalidPassword = !passwordRegex.test(val.password ?? '');
    if (existingAccount && nonEmptyPassword && !val.oldPassword) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'user.registration.form.oldPassword.mandatory',
            path: ['oldPassword'],
        });
    }
    // if (nonEmptyPassword && invalidPassword) {
    //   ctx.addIssue({
    //     code: z.ZodIssueCode.custom,
    //     message: 'user.registration.form.password.criteria',
    //     path: ['password'],
    //   });
    // }
    if (((_a = val.password) !== null && _a !== void 0 ? _a : '') !== ((_b = val.passwordValidation) !== null && _b !== void 0 ? _b : '')) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'user.registration.form.password.no.match',
            path: ['passwordValidation'],
        });
    }
});
