"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingUpdateSchema = exports.settingCreateSchema = exports.settingGetSchema = exports.settingModelTempSchema = exports.settingDatesCollectionModelSchema = exports.settingDatesModelSchema = exports.settingListGetSchema = void 0;
const zod_1 = require("zod");
exports.settingListGetSchema = zod_1.z.object({
    key: zod_1.z.string(),
    languageCode: zod_1.z.string().nullable(),
});
exports.settingDatesModelSchema = zod_1.z.object({
    key: zod_1.z.string(),
    default: zod_1.z.boolean(),
    dates: zod_1.z.date().array(),
});
exports.settingDatesCollectionModelSchema = zod_1.z.array(exports.settingDatesModelSchema);
exports.settingModelTempSchema = zod_1.z.object({
    temp: zod_1.z.string(),
});
exports.settingGetSchema = exports.settingListGetSchema.extend({
    id: zod_1.z.string(),
    details: zod_1.z.union([exports.settingDatesCollectionModelSchema, exports.settingModelTempSchema]),
});
exports.settingCreateSchema = exports.settingGetSchema.omit({ id: true });
exports.settingUpdateSchema = exports.settingGetSchema.omit({});
