"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applicationInvitationMessageSchema = exports.notificationMessageSchema = exports.messageSchema = void 0;
const zod_1 = require("zod");
exports.messageSchema = zod_1.z.object({
    sms: zod_1.z.object({
        message: zod_1.z.string(),
    }),
    email: zod_1.z.object({
        subject: zod_1.z.string(),
        message: zod_1.z.string(),
    }),
    parameters: zod_1.z.map(zod_1.z.string(), zod_1.z.string()).optional(),
    recipients: zod_1.z.string().array().min(1, { message: 'at.least.one.recipient' }),
});
exports.notificationMessageSchema = exports.messageSchema.omit({
    email: true,
});
exports.applicationInvitationMessageSchema = zod_1.z.object({
    invitationTo: zod_1.z.string(),
    invitationUrl: zod_1.z.string(),
    language: zod_1.z.string(),
    emailAddresses: zod_1.z
        .string()
        .array()
        .min(1, { message: 'at.least.one.message.recipient' }),
});
