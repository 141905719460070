"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAccountRoleManagedAreasSchema = void 0;
const zod_1 = require("zod");
const src_1 = require("@ackreditering/type-safeguards/src");
const getRoleSchema = zod_1.z.object({
    role: zod_1.z.string(),
    description: zod_1.z.string(),
});
exports.setAccountRoleManagedAreasSchema = zod_1.z
    .object({
    accountId: zod_1.z.string(),
    role: zod_1.z.string(),
    managedAreas: zod_1.z.string().array().optional(),
})
    .refine((r) => r.role !== src_1.AccountRoleEnum.areaManager ||
    (r.managedAreas && r.managedAreas.length > 0));
