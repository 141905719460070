"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.equipmentInfoMiniGetSchema = exports.equipmentQuantityUpdateSchema = exports.equipmentReleaseReturnSchema = exports.equipmentUpdateSchema = exports.equipmentCreateSchema = exports.equipmentSelectedSchema = exports.equipmentGetSchema = exports.equipmentAccountReferenceSchema = exports.equipmentTypeUpdateSchema = exports.equipmentTypeCreateSchema = exports.equipmentTypeGetSchema = void 0;
const zod_1 = require("zod");
exports.equipmentTypeGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    key: zod_1.z.string().min(1, 'equipment.form.error.key.mandatory'),
    descriptions: zod_1.z
        .string()
        .min(1, 'equipment.form.error.descriptions.mandatory'),
    availableTo: zod_1.z
        .string()
        .array()
        .min(1, 'equipment.form.error.availableTo.at.least.one'),
    requestReturn: zod_1.z
        .string()
        .min(1, 'equipment.form.error.requestReturn.mandatory'),
});
exports.equipmentTypeCreateSchema = exports.equipmentTypeGetSchema.omit({
    id: true,
});
exports.equipmentTypeUpdateSchema = exports.equipmentTypeGetSchema.omit({});
exports.equipmentAccountReferenceSchema = zod_1.z.object({
    id: zod_1.z.string(),
    email: zod_1.z.string().email().optional(),
    name: zod_1.z.string().optional(),
});
exports.equipmentGetSchema = exports.equipmentTypeGetSchema
    .omit({
    availableTo: true,
})
    .extend({
    assignedTo: exports.equipmentAccountReferenceSchema,
    assignedBy: exports.equipmentAccountReferenceSchema,
    quantityToRelease: zod_1.z
        .number()
        .min(0, 'equipment.quantity.must.not.be.negative'),
    quantityReleased: zod_1.z
        .number()
        .min(0, 'equipment.quantity.must.not.be.negative'),
    quantityReturned: zod_1.z
        .number()
        .min(0, 'equipment.quantity.must.not.be.negative'),
});
exports.equipmentSelectedSchema = exports.equipmentTypeGetSchema
    .omit({
    availableTo: true,
})
    .extend({
    quantityToRelease: zod_1.z
        .number()
        .min(0, 'equipment.quantity.must.not.be.negative'),
});
exports.equipmentCreateSchema = exports.equipmentGetSchema
    .omit({
    id: true,
})
    .superRefine((data, ctx) => {
    if (data.quantityReturned > data.quantityReleased) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['quantityReturned'],
            message: 'equipment.form.error.quantityReturned.higher.than.quantity',
        });
    }
});
exports.equipmentUpdateSchema = exports.equipmentGetSchema
    .omit({})
    .superRefine((data, ctx) => {
    if (data.quantityReturned > data.quantityReleased) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            path: ['quantityReturned'],
            message: 'equipment.form.error.quantityReturned.higher.than.quantity',
        });
    }
});
exports.equipmentReleaseReturnSchema = exports.equipmentUpdateSchema;
exports.equipmentQuantityUpdateSchema = zod_1.z.object({
    id: zod_1.z.string(),
    quantityReleased: zod_1.z.number(),
    quantityReturned: zod_1.z.number(),
});
exports.equipmentInfoMiniGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    key: zod_1.z.string().min(1),
    descriptions: zod_1.z.string().min(1),
    requestReturn: zod_1.z.string().min(1),
    quantityToRelease: zod_1.z.number(),
    quantityReleased: zod_1.z.number(),
    quantityReturned: zod_1.z.number(),
});
