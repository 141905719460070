export enum Currency {
  DKK = 'DKK',
  SEK = 'SEK',
}

export const isSupportedCurrency = (currency: string) =>
  Currency[currency as keyof typeof Currency] != null;

export enum PaymentMethod {
  Card = 1,
  Invoice = 2,
}
