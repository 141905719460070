import Header from './header';
import Footer from './footer';
import type { ReactNode } from 'react';
import styles from './layout.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <div className={styles.container}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
