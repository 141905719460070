"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.performPasswordResetSchema = exports.createPasswordResetTokenSchema = exports.passwordResetRequestSchema = exports.accountRegistrationSchema = exports.getFullAccountSchema = exports.getAccountPressNodeSchema = exports.getAccountCrewNodeSchema = exports.getAccountInfoSchema = exports.getBasicAccountSchema = exports.pressDetailsUpdateSchema = exports.pressDetailsGetSchema = exports.crewDetailsUpdateSchema = exports.crewDetailsGetSchema = void 0;
const zod_1 = require("zod");
const crewApplicationLockOut_1 = require("./crewApplicationLockOut");
const crew_1 = require("../crew");
const accountMisc_1 = require("./accountMisc");
const press_1 = require("../press");
exports.crewDetailsGetSchema = zod_1.z.object({
    areas: zod_1.z.string().array().optional(),
    priorExperience: zod_1.z.string().optional(),
    canWorkNumberHours: zod_1.z.number(),
    canWorkDates: zod_1.z.date().array(),
    canWorkConsecutiveShifts: zod_1.z.boolean(),
    shirtSize: zod_1.z.enum(crew_1.shirtSizes),
    gdprConsentDate: zod_1.z.date().nullish(),
    workTermsApprovalDate: zod_1.z.date(),
    afterFestival: zod_1.z.enum(crew_1.accountActionsAfterFestival),
    okToSendImportantCrewInformation: zod_1.z.object({
        asEmail: zod_1.z.boolean(),
        asTextMessages: zod_1.z.boolean(),
    }),
    okToSendImportantCrewAlerts: zod_1.z.object({
        asTextMessages: zod_1.z.boolean(),
    }),
    foodPreference: zod_1.z.string().nullish(),
    allergies: zod_1.z.string().optional(),
    additionalInfo: zod_1.z.string().optional(),
});
exports.crewDetailsUpdateSchema = exports.crewDetailsGetSchema;
exports.pressDetailsGetSchema = zod_1.z.object({
    writingFor: zod_1.z.string().min(3),
    gdprConsentDate: zod_1.z.date().nullish(),
    additionalInfo: zod_1.z.string().optional().or(zod_1.z.literal('')),
});
exports.pressDetailsUpdateSchema = exports.pressDetailsGetSchema;
exports.getBasicAccountSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    email: zod_1.z.string(),
    emailVerified: zod_1.z.date().optional(),
    phoneNumber: zod_1.z.string().nullable().optional(),
    address: zod_1.z.string().nullable().optional(),
    postalCode: zod_1.z.string().nullable().optional(),
    city: zod_1.z.string().nullable().optional(),
    country: zod_1.z.string().nullable().optional(),
    image: zod_1.z.string().nullable().optional(),
    personalIdentificationNumber: zod_1.z.string().nullable().optional(),
    accountLocked: zod_1.z.boolean(),
    userRole: zod_1.z.string().optional(),
    createdAt: zod_1.z.date(),
    lastUpdateAt: zod_1.z.date().optional(),
    crewApproved: zod_1.z.boolean(),
    passwordIsSet: zod_1.z.boolean(),
});
exports.getAccountInfoSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    email: zod_1.z.string(),
    phoneNumber: zod_1.z.string().nullable().optional(),
    personalIdentificationNumber: zod_1.z.string().nullable().optional(),
    accountLocked: zod_1.z.boolean().optional(),
    userRole: zod_1.z.string().optional(),
    crewStatus: zod_1.z.enum(crew_1.crewStatuses),
    crewAreas: zod_1.z.string().array(),
    shirtSize: zod_1.z.enum(crew_1.shirtSizes).optional().nullable(),
    accountLockedForCrewApplication: crewApplicationLockOut_1.crewApplicationLockOutSchema.optional(),
    pressStatus: zod_1.z.enum(press_1.pressStatuses),
    managedAreas: zod_1.z.string().array().optional(),
    createdAt: zod_1.z.date().optional(),
});
exports.getAccountCrewNodeSchema = zod_1.z.object({
    approvedBy: accountMisc_1.accountReferenceWithDateSchema.optional(),
    rejectedBy: accountMisc_1.accountReferenceWithDateSchema.optional(),
    details: exports.crewDetailsGetSchema.nullable().optional(),
    managedAreas: zod_1.z.string().array().nullable().optional(),
});
exports.getAccountPressNodeSchema = zod_1.z.object({
    approvedBy: accountMisc_1.accountReferenceWithDateSchema.optional(),
    rejectedBy: accountMisc_1.accountReferenceWithDateSchema.optional(),
    details: exports.pressDetailsGetSchema.nullable().optional(),
});
exports.getFullAccountSchema = exports.getAccountInfoSchema
    .omit({
    crewStatus: true,
    crewAreas: true,
    pressStatus: true,
    managedAreas: true,
})
    .extend({
    emailVerified: zod_1.z.date().optional(),
    phoneNumber: zod_1.z.string().nullable().optional(),
    address: zod_1.z.string().nullable().optional(),
    postalCode: zod_1.z.string().nullable().optional(),
    city: zod_1.z.string().nullable().optional(),
    country: zod_1.z.string().nullable().optional(),
    image: zod_1.z.string().nullable().optional(),
    personalIdentificationNumber: zod_1.z.string().nullable().optional(),
    passwordLockedOutUntilDate: zod_1.z.date().nullable().optional(),
    crew: exports.getAccountCrewNodeSchema.optional(),
    press: exports.getAccountPressNodeSchema.optional(),
    lastUpdateAt: zod_1.z.date().optional(),
});
exports.accountRegistrationSchema = zod_1.z
    .object({
    firstName: zod_1.z
        .string()
        .min(1, 'user.registration.form.firstName.must.be.long'),
    lastName: zod_1.z.string().min(1, 'user.registration.form.lastName.must.be.long'),
    email: zod_1.z
        .string()
        .min(1, 'user.registration.form.email.mandatory')
        .email('user.registration.form.email.invalid'),
    password: zod_1.z
        .string()
        // .regex(/^(?=.*\d)(?=.*[A-Za-z]).{10,}$/gm, {
        //   message: 'user.registration.form.password.criteria.hint',
        // })
        .optional()
        .or(zod_1.z.literal('')),
    passwordValidation: zod_1.z.string().optional().or(zod_1.z.literal('')),
    phoneNumber: zod_1.z
        .string()
        .min(6, 'user.registration.form.phoneNumber.mandatory')
        .regex(/^\+\d{8,}$/gm, 'user.registration.form.phoneNumber.invalid')
        .optional()
        .or(zod_1.z.literal('')),
    address: zod_1.z.string().optional().or(zod_1.z.literal('')),
    postalCode: zod_1.z.string().optional().or(zod_1.z.literal('')),
    city: zod_1.z.string().optional().or(zod_1.z.literal('')),
    country: zod_1.z.string().optional().or(zod_1.z.literal('')),
    image: zod_1.z.string().optional().or(zod_1.z.literal('')),
    gdprConsent: zod_1.z.literal(true, {
        errorMap: () => ({ message: 'user.registration.form.error.gdprConsent' }),
    }),
})
    .superRefine((val, ctx) => {
    var _a, _b;
    if (val.password && val.password.length < 10) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'user.registration.form.password.criteria.hint',
            path: ['password'],
        });
    }
    if (((_a = val.password) !== null && _a !== void 0 ? _a : '') !== ((_b = val.passwordValidation) !== null && _b !== void 0 ? _b : '')) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'user.registration.form.password.no.match',
            path: ['passwordValidation'],
        });
    }
});
exports.passwordResetRequestSchema = zod_1.z.object({
    email: zod_1.z.string().email(),
    locale: zod_1.z.string().optional(),
});
exports.createPasswordResetTokenSchema = zod_1.z.object({
    userId: zod_1.z.string(),
    email: zod_1.z.string().email(),
});
exports.performPasswordResetSchema = zod_1.z
    .object({
    email: zod_1.z.string().email().min(1, 'Email must be specified'),
    token: zod_1.z.string().min(1, 'Token must be specified'),
    password: zod_1.z.string().min(5, 'Password must be at least 5 characters long'),
    passwordValidation: zod_1.z.string(),
})
    .superRefine((val, ctx) => {
    if (val.password !== val.passwordValidation) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'user.registration.form.password.no.match',
            path: ['passwordValidation'],
        });
    }
});
