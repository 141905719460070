"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areaUpdateSchema = exports.areaCreateSchema = exports.areaGetSchema = void 0;
const zod_1 = require("zod");
exports.areaGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    area: zod_1.z.string().min(3),
    descriptiveName: zod_1.z.string().min(3),
    availableInApplication: zod_1.z.boolean(),
    availableToAssign: zod_1.z.boolean(),
});
exports.areaCreateSchema = exports.areaGetSchema.omit({ id: true });
exports.areaUpdateSchema = exports.areaGetSchema.omit({});
