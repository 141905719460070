"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.guestListEditorUISchema = exports.guestListUpdateSchema = exports.guestListCreateSchema = exports.guestListGetSchema = exports.guestUpdateUISchema = exports.guestCreateUISchema = exports.guestUpdateSchema = exports.guestCreateSchema = exports.guestGetSchema = void 0;
const zod_1 = require("zod");
const account_1 = require("../account");
const tickets_1 = require("../tickets");
const misc_1 = require("../misc");
exports.guestGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    email: zod_1.z.string().email().optional().or(zod_1.z.literal('')),
    name: zod_1.z.string().optional().or(zod_1.z.literal('')),
    ssn: zod_1.z.string().optional().or(zod_1.z.literal('')),
    phoneNumber: zod_1.z.string().optional().or(zod_1.z.literal('')),
    companyName: zod_1.z.string().optional().or(zod_1.z.literal('')),
    equipment: misc_1.equipmentInfoMiniGetSchema.array().optional(),
    ticketType: tickets_1.ticketTypeGetSchema,
    used: zod_1.z.boolean(),
});
exports.guestCreateSchema = exports.guestGetSchema;
exports.guestUpdateSchema = exports.guestGetSchema.omit({
    equipment: true,
    used: true,
});
exports.guestCreateUISchema = exports.guestGetSchema.extend({
    ticketType: zod_1.z
        .string()
        .min(1, { message: 'guest.list.form.guest.ticketType.error.mandatory' }),
});
exports.guestUpdateUISchema = exports.guestGetSchema.extend({
    ticketType: zod_1.z
        .string()
        .min(1, { message: 'guest.list.form.guest.ticketType.error.mandatory' }),
});
exports.guestListGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z
        .string()
        .min(3, { message: 'guest.list.form.name.error.minimum.length' }),
    managedBy: account_1.accountReferenceSchema.array(),
    maxGuests: zod_1.z
        .number()
        .int()
        .min(1, { message: 'guest.list.form.maxGuests.error.positive.number' }),
    guests: exports.guestGetSchema.array(),
});
exports.guestListCreateSchema = exports.guestListGetSchema.omit({
    id: true,
});
exports.guestListUpdateSchema = exports.guestListGetSchema;
exports.guestListEditorUISchema = exports.guestListGetSchema;
