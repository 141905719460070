"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ticketUseUpdateSchema = exports.ticketUpdateSchema = exports.ticketCreateSchema = exports.ticketGetSchema = exports.ticketTypeUpdateSchema = exports.ticketTypeCreateSchema = exports.ticketTypeGetNoIdSchema = exports.ticketTypeGetSchema = void 0;
const zod_1 = require("zod");
const reference_1 = require("../misc/reference");
exports.ticketTypeGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().min(1, { message: 'ticket.type.form.error.name.mandatory' }),
    description: zod_1.z
        .string()
        .min(1, { message: 'ticket.type.form.error.description.mandatory' }),
    numberOfDays: zod_1.z
        .number()
        .int()
        .min(1, { message: 'ticket.type.form.error.numberOfDays.minimum' }),
    validFromDate: zod_1.z.date().optional(),
});
exports.ticketTypeGetNoIdSchema = exports.ticketTypeGetSchema.omit({
    id: true,
});
exports.ticketTypeCreateSchema = exports.ticketTypeGetSchema.omit({
    id: true,
});
exports.ticketTypeUpdateSchema = exports.ticketTypeGetSchema;
exports.ticketGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    ticketType: exports.ticketTypeGetNoIdSchema,
    belongsTo: reference_1.personOrOrganizationGetSchema,
    usedInstances: zod_1.z.date().array(),
    numberUsed: zod_1.z
        .number()
        .int()
        .min(1, { message: 'ticket.form.error.numberUsed.minimum' }),
    purchaseTime: zod_1.z.date({
        errorMap: () => ({ message: 'ticket.form.error.purchaseTime.invalid' }),
    }),
});
exports.ticketCreateSchema = exports.ticketGetSchema.omit({
    id: true,
    usedInstances: true,
    numberUsed: true,
});
exports.ticketUpdateSchema = exports.ticketGetSchema.omit({
    usedInstances: true,
    numberUsed: true,
});
exports.ticketUseUpdateSchema = zod_1.z.object({
    id: zod_1.z.string(),
    date: zod_1.z.date(),
});
