"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentFilterSchema = exports.contentGetInfoSchema = exports.contentGetSchema = exports.contentQuerySchema = exports.contentGetFullSchema = exports.contentUpdateSchema = exports.contentCreateSchema = void 0;
const zod_1 = require("zod");
exports.contentCreateSchema = zod_1.z.object({
    key: zod_1.z.string().min(1),
    language: zod_1.z.string().length(2),
    text: zod_1.z.string(),
    active: zod_1.z.boolean(),
});
exports.contentUpdateSchema = exports.contentCreateSchema
    .omit({
    key: true,
    language: true,
})
    .extend({
    id: zod_1.z.string(),
});
exports.contentGetFullSchema = zod_1.z.object({
    id: zod_1.z.string(),
    key: zod_1.z.string(),
    language: zod_1.z.string(),
    text: zod_1.z.string(),
    template: zod_1.z.string().optional(),
    active: zod_1.z.boolean(),
    createdAt: zod_1.z.date(),
    lastUpdateAt: zod_1.z.date().optional(),
});
exports.contentQuerySchema = zod_1.z.object({
    key: zod_1.z.string().array(),
    language: zod_1.z.string(),
});
exports.contentGetSchema = zod_1.z.object({
    id: zod_1.z.string(),
    key: zod_1.z.string(),
    language: zod_1.z.string(),
    text: zod_1.z.string(),
    template: zod_1.z.string().optional(),
    active: zod_1.z.boolean(),
    createdAt: zod_1.z.date(),
    lastUpdateAt: zod_1.z.date().optional(),
});
exports.contentGetInfoSchema = exports.contentGetSchema.omit({
    createdAt: true,
    lastUpdateAt: true,
});
exports.contentFilterSchema = zod_1.z.object({
    key: zod_1.z.string().optional(),
    language: zod_1.z.string().optional(),
    active: zod_1.z.boolean().optional(),
});
