export enum AccountType {
  SYSTEM_ADMIN = 0,
  STAFF = 1,
  PRESS = 21,
}

export enum AccountApplicationStatusEnum {
  Approved = 'approved',
  Rejected = 'rejected',
  No = 'no',
}

export enum AccountRoleEnum {
  superAdmin = 'super admin',
  admin = 'admin',
  areaManager = 'area manager',
  visitor = 'visitor',
}
