"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.personOrOrganizationGetSchema = void 0;
const zod_1 = require("zod");
const reference_1 = require("@ackreditering/type-safeguards/src/reference");
exports.personOrOrganizationGetSchema = zod_1.z.object({
    referenceId: zod_1.z
        .string()
        .min(1, { message: 'ticket.type.form.error.referenceId.mandatory' }),
    name: zod_1.z.string().min(1, { message: 'ticket.type.form.error.name.mandatory' }),
    type: zod_1.z.enum([
        reference_1.EntityTypesEnum.person,
        reference_1.EntityTypesEnum.organization,
        reference_1.EntityTypesEnum.band,
    ], {
        errorMap: () => ({ message: 'ticket.type.form.error.type.unrecognized' }),
    }),
});
