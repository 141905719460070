"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commentSchema = exports.commentUpdateSchema = exports.commentCreateSchema = void 0;
const zod_1 = require("zod");
const account_1 = require("../account");
exports.commentCreateSchema = zod_1.z.object({
    text: zod_1.z.string().min(5),
});
exports.commentUpdateSchema = zod_1.z.object({
    text: zod_1.z.string().min(5),
});
exports.commentSchema = zod_1.z.object({
    id: zod_1.z.string(),
    postedBy: account_1.accountReferenceSchema,
    postedAt: zod_1.z.date(),
    text: zod_1.z.string(),
});
