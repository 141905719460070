import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useRouter } from 'next/router';
import Image, { StaticImageData } from 'next/image';
import svFlag from '../../public/flags/se.webp';
import enFlag from '../../public/flags/en.webp';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import _ from 'lodash';

export default function NavLocalization() {
  const { pathname, locale, locales } = useRouter();
  const intl = useIntl();
  const flags: Record<string, StaticImageData> = {
    sv: svFlag,
    en: enFlag,
  };

  return (
    <Nav style={{ maxHeight: '100px' }} navbarScroll>
      <NavDropdown
        title={locales
          ?.filter((lo) => lo === locale)
          .map((lo) => {
            const langCode = lo.substring(0, 2);
            const title = _.capitalize(
              intl.formatDisplayName('sv', { type: 'language' }) || ''
            );
            const flag = (flags as any)[langCode];
            return (
              <Image key={Math.random()} src={flag} alt={title} title={title} />
            );
          })}
        id="navbarProfileDropdown"
        align={'end'}
        menuVariant="dark"
      >
        {locales?.map((lo) => {
          const langCode = lo.substring(0, 2);
          const title = intl.formatMessage({ id: `switch.to.${langCode}` });
          const text =
            intl.formatDisplayName(langCode, { type: 'language' }) || '';
          const flag = flags[langCode];
          return (
            <Link
              key={Math.random()}
              locale={langCode}
              href={pathname}
              className={'nav-link'}
            >
              <Image
                src={flag}
                alt={title}
                title={title}
                style={{ marginRight: '10px' }}
              />
              {_.capitalize(text)}
            </Link>
          );
        })}
      </NavDropdown>
    </Nav>
  );
}
