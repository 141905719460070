import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { signIn, signOut, useSession } from 'next-auth/react';
import Image from 'react-bootstrap/Image';
import AnonymousProfilePicture from '../../public/account/AnonymousProfile.webp';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default function NavBarProfile() {
  const { data: session } = useSession();

  const profilePictureUrl = session?.user?.image || AnonymousProfilePicture.src;
  const ProfilePicture = (
    <Image
      src={profilePictureUrl}
      alt="Profile image"
      roundedCircle={true}
      style={{ width: '40px' }}
    />
  );
  const signInOutAction = session ? () => signOut() : () => signIn();

  return (
    <Nav style={{ maxHeight: '100px' }} navbarScroll>
      <NavDropdown
        title={ProfilePicture}
        id="navbarProfileDropdown"
        align={'end'}
        menuVariant="dark"
      >
        <Navbar.Text className="dropdown-item">
          {session && (
            <>
              <small>
                <FormattedMessage id={'signed.in.as'} />
              </small>
              <br />
              {session.user?.name ?? session.user?.email}
            </>
          )}
          {!session && (
            <small>
              <FormattedMessage id={'not.signed.in'} />
            </small>
          )}
        </Navbar.Text>
        <NavDropdown.Divider />
        <NavDropdown.Item
          onClick={(e: { preventDefault: () => void }) => {
            e.preventDefault();
            signInOutAction();
          }}
        >
          <FormattedMessage id={session ? 'logout' : 'login'} />
        </NavDropdown.Item>
        {session && (
          <NavDropdown.Item href={'/account/profile'}></NavDropdown.Item>
        )}
        {!session && (
          <>
            {/*TODO Add page for emailing an email password reset link*/}
            {/*<NavDropdown.Item href={'/forgot-password'}>*/}
            {/*  <FormattedMessage id={'forgot.password'} />*/}
            {/*</NavDropdown.Item>*/}
            <NavDropdown.Item href={'/new-account/register'}>
              <FormattedMessage id={'register.new.account'} />
            </NavDropdown.Item>
          </>
        )}
      </NavDropdown>
    </Nav>
  );
}
