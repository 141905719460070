import { SessionProvider } from 'next-auth/react';
import '../../scss/custom.scss';
import '../../styles/styles.css';
import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import SSRProvider from 'react-bootstrap/SSRProvider';
import { useRouter } from 'next/router';
import { IntlProvider } from 'react-intl';
import sv from '../../lang/sv.json';
import en from '../../lang/en.json';
import '../../styles/shared.css';
import LogRocket from 'logrocket';

const messages: Record<string, Record<string, string>> = {
  sv,
  en,
};

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  // refetchInterval={5 * 60}
  const { locale = 'sv' } = useRouter();

  if (process.env.NODE_ENV === 'development') {
    LogRocket.init('q1bvhf/time-to-rock');
  }

  return (
    <SSRProvider>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <SessionProvider session={session}>
          <Component {...pageProps} />
        </SessionProvider>
      </IntlProvider>
    </SSRProvider>
  );
}
